$(document).ready(function () {
    
laravel.errors.showErrorsBag = false;
laravel.errors.showErrorsInFormGroup = true;

if (!window.tableInit) {
window.tableInit = function (table)
{
console.log("tableInit");
console.log($(table));

$(table).on('click', '[data-toggle="etab"]', function (e) {
e.preventDefault();
console.log("click");
console.log($(this));
var url = $(this).attr('href');
var title = $(this).data('etab-title');
EzAddTab('tab', url, title);

});

};
};    
    
function EzAddTab(tabid, tab_url, title)

        {
        var tab = '#' + tabid;
        var nextTab = $(tab + '-ul li').length + 1;
        $('<li><a href="' + tab + '-' + nextTab + '" data-tab-url="' + tab_url + '"  data-toggle="tab"><span><i class="fa fa-times" aria-hidden="true"></i></span> ' + title + ' (' + nextTab + ') </a></li>').appendTo(tab + '-ul');
        $('<div class="tab-pane" id="' + tabid + '-' + nextTab + '"></div>').appendTo(tab + '-content');
        remoteTabsPluginLoaded.register($(tab + '-ul a:last'));
        $(tab + '-ul a:last').on("click", "span", function () {
        var anchor = $(this).siblings('a');
        $('#' + tabid + '-' + nextTab).remove();
        $(this).parent().remove();
        $(tab + '-ul a:last').tab('show');
        });
        $(tab + '-ul a:last').tab('show');
        }

});
 
